<template>
  <v-card style="background-color:inherit">
    <v-toolbar>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :to="'/forms/admin'" active-class="no-active" class="no-print" icon>
            <v-icon>fas fa-list</v-icon>
          </v-btn>
        </template>
        <span>Back to form list</span>
      </v-tooltip>
      <v-text-field v-if="editingName" v-model="name" ref="nameEditor" solo hide-details @blur="checkUniqueName">
        <template v-slot:prepend-inner>Form Name:</template>
        <template v-slot:append>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon color="info" v-on="on">fas fa-question-circle</v-icon>
            </template>
            <div style="max-width:300px">The form name must be unique. When you click out of the name editor, the system will check and make sure that another form with the same doesn't already exist. If it does, you will need to update to a unique name.</div>
          </v-tooltip>
        </template>
      </v-text-field>
      <template v-else>
        <v-toolbar-title>{{ name }}</v-toolbar-title>
        <v-tooltip v-if="accessRole === 'Admin'" top>
          <template v-slot:activator="{ on }">
            <v-btn class="no-print" icon @click="startNameEdit" v-on="on">
              <v-icon small>fal fa-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit the form name</span>
        </v-tooltip>
      </template>
      <v-spacer></v-spacer>
      <help-dialog></help-dialog>
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on: menuOn }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltipOn }">
              <v-btn v-on="{ ...menuOn, ...tooltipOn }" :color="status === 'Active' ? 'success' : (status === 'Retired' ? 'error' : '')" class="no-print" text>
                Status: {{ status }}
                <v-icon right>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
            <span v-if="status === 'Active'">The form is currently active. This means the form is accessible via the short link{{ inList ? ' and will be visible in the list of active forms' : '' }}.</span>
            <span v-else-if="status === 'Retired'">The form is retired. The form is not accessible via the short link, will not show up in the list of active forms, and will not show up in the form admin list except for admins of the form (hidden for editors and reviewers).</span>
            <span v-else>The form is inactive. The form is not accessible via the short link and will not show up in the list of active forms, but is accessible in the form admin list for all who have access.</span>
          </v-tooltip>
        </template>
        <v-list dense>
          <v-list-item :disabled="status === 'Active'" @click="changeActiveStatus('Active')">
            <v-list-item-content>
              <v-list-item-title>Make Form Active</v-list-item-title>
              <v-list-item-subtitle>Makes the form available via the short link and in the forms list (if option is checked).</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :disabled="status === 'Inactive'" @click="changeActiveStatus('Inactive')">
            <v-list-item-content>
              <v-list-item-title>Make Form Inactive</v-list-item-title>
              <v-list-item-subtitle>Hides the form from the forms list but still available to admins, editors, and reviewers.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :disabled="status === 'Retired'" @click="changeActiveStatus('Retired')">
            <v-list-item-content>
              <v-list-item-title>Make Form Retired</v-list-item-title>
              <v-list-item-subtitle>Hides the form from all but admins set on the form.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn class="no-print" icon v-on="on" :to="'/forms/' + id + '/' + revisionId">
            <v-icon>fal fa-eye</v-icon>
          </v-btn>
        </template>
        <span>Preview Form</span>
      </v-tooltip>
    </v-toolbar>
    <v-tabs v-model="selectedTab" @change="updateActivityTimer" class="no-print">
      <v-tab v-if="accessRole === 'Admin'">Access</v-tab>
      <v-tab v-if="accessRole === 'Admin' || accessRole === 'Editor'">Form</v-tab>
      <v-tab v-if="accessRole === 'Admin' && user.username === 'jon.moon'">Payment</v-tab>
      <v-tab v-if="accessRole === 'Admin' || accessRole === 'Editor'">Confirmation</v-tab>
      <v-tab v-if="accessRole === 'Admin' && 'Technology Services' in userRoles">Notifications</v-tab>
      <v-tab v-if="accessRole === 'Admin' || accessRole === 'Reviewer'">Submissions</v-tab>
      <v-tab v-if="accessRole !== ''">Revisions</v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab" style="background-color:inherit">
      <!-- Access control tab -->
      <v-tab-item v-if="accessRole === 'Admin'">
        <form-access :formId="id" :revisionId="revisionId"></form-access>
      </v-tab-item>
      <!-- Form Editor Tab -->
      <v-tab-item v-if="accessRole === 'Admin' || accessRole === 'Editor'">
        <v-toolbar>
          <v-select v-model="revisionId" :items="revisions" label="Revision" item-value="_id" outlined hide-details dense style="max-width:170px;margin-right:1em">
            <template v-slot:selection="{ item }">
              Version: {{ item.version }}
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>Version: {{ item.version }}</v-list-item-title>
                <v-list-item-subtitle v-if="item._id === activeRevision">Active Revision</v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="item._id === editingRevision">Editing Revision</v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <template v-slot:append-item>
              <v-divider></v-divider>
              <v-list-item @click="newRevision">
                <v-list-item-content style="border-top:1px solid gray">
                  <v-list-item-title>Create New Revision</v-list-item-title>
                  <v-list-item-subtitle>Will copy the currently-loaded revision</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
          <!-- Revision Active menu -->
          <v-menu v-if="!revisionActive" bottom offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text>Revision is Inactive</v-btn>
            </template>
            <v-list>
              <v-list-item @click="makeRevisionActive">
                <v-list-item-title>Make This Revision Active</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn v-else text disabled>Revision is Active</v-btn>
          <v-spacer></v-spacer>
          <!-- Form Settings Dialog -->
          <v-dialog v-model="formSettingsDialog" width="600">
            <template v-slot:activator="{ on: dialogOn }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltipOn }">
                  <v-btn v-on="{ ...dialogOn, ...tooltipOn }">
                    Form Settings ({{ sectionTypeText }})
                    <v-icon right>far fa-cog</v-icon>
                  </v-btn>
                </template>
                <span>Form Settings (section type, hide/show title, include info card, etc)</span>
              </v-tooltip>
            </template>
            <v-card>
              <v-card-title>Form Settings</v-card-title>
              <v-card-text>
                <v-checkbox v-model="showTitle" label="Show Title at top of form" hide-details></v-checkbox>
                <v-checkbox v-model="infoCard" label="Show Info Card at top of form" hide-details>
                  <template v-slot:append>
                    <v-dialog v-model="infoCardHelp" width="600">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="info" icon style="height:24px;width:24px;">
                          <v-icon>fas fa-question-circle</v-icon>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>Info Card</v-card-title>
                        <v-card-text>The info card is a card of formatted text that will appear at the top of the form. This card will remain visible even if the form contains multiple sections and is a stepper where only the active section is visible. This is a good place to put instructions or general information about the form.</v-card-text>
                        <v-card-actions>
                          <v-btn text @click="infoCardHelp = false">Close</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-checkbox>
                <v-checkbox v-model="asteriskOnRequired" label="Show asterisk on required fields"></v-checkbox>
                <v-select v-model="sectionType" :items="sectionTypes" label="Section Type" hide-details dense outlined></v-select>
                <v-alert type="info" outlined style="margin:2em 0 0">Note: You will need to click the Save button in the bottom-right corner for these changes to take effect. Setting them here will let you preview the changes in the editor but are not saved until you click the Save button.</v-alert>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="formSettingsDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-card v-if="infoCard">
          <html-editor v-model="infoCardText"></html-editor>
        </v-card>
        <v-card-text v-if="asteriskOnRequired" style="padding-top:8px;padding-bottom:8px;cursor:pointer" @click="formSettingsDialog = true">All required fields will be marked with <span class="error--text fas fa-asterisk" style="font-size:.6em;transform:translateY(-.6em);margin-left:.2em;"></span> (click here to change)</v-card-text>
        <v-card-text v-else style="padding-top:8px;padding-bottom:8px;cursor:pointer" @click="formSettingsDialog = true">Required fields will not be automatically marked, though they are marked here for your reference (click here to change)</v-card-text>
        <sections :section-type="sectionType" :sections="sections" @change="updateSection" @removeSection="removeSection"></sections>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="success" fab bottom fixed right @click="save">
              <v-icon>fal fa-save</v-icon>
            </v-btn>
          </template>
          <span>Save Changes</span>
        </v-tooltip>
        <v-toolbar>
          <v-btn color="success" style="margin-right:2em" @click="save">
            <v-icon left>fal fa-save</v-icon>
            Save Changes
          </v-btn>
          <v-btn color="success" outlined @click="addSection">
            <v-icon left>fal fa-plus-circle</v-icon>
            Add Section
          </v-btn>
        </v-toolbar>
      </v-tab-item>
      <!-- Payment Settings Tab -->
      <v-tab-item v-if="accessRole === 'Admin' && user.username === 'jon.moon'">
        <payment-tab :formId="id" :revisionId="revisionId"></payment-tab>
      </v-tab-item>
      <!-- On-Submit Tab -->
      <v-tab-item v-if="accessRole === 'Admin' || accessRole === 'Editor'">
        <form-confirmation :formId="id" :revisionId="revisionId"></form-confirmation>
      </v-tab-item>
      <!-- Notifications Tab -->
      <v-tab-item v-if="accessRole === 'Admin' && 'Technology Services' in userRoles">
        <notifications :formId="id" :revisionId="revisionId"></notifications>
      </v-tab-item>
      <!-- Submission Viewer Tab -->
      <v-tab-item v-if="accessRole === 'Admin' || accessRole === 'Reviewer'">
        <submissions :formId="id"></submissions>
      </v-tab-item>
      <!-- Form Revisions Tab -->
      <v-tab-item v-if="accessRole !== ''">
        <form-revisions :formId="id" :access-role="accessRole" @changeEditRevision="selectRevision" @revisionRemoved="loadRevisions"></form-revisions>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
export default {
  components: {
    Sections: () => import('@/components/forms/editor/sections'),
    HtmlEditor: () => import('@/components/forms/HTMLEditor'),
    HelpDialog: () => import('@/components/forms/editor/help'),
    FormAccess: () => import('@/components/forms/admin/Access'),
    PaymentTab: () => import('@/components/forms/admin/Payment'),
    FormConfirmation: () => import('@/components/forms/admin/Confirmation'),
    Notifications: () => import('@/components/forms/admin/Notifications'),
    Submissions: () => import('@/components/forms/admin/Submissions'),
    FormRevisions: () => import('@/components/forms/admin/Revisions')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const userRoles = computed(() => root.$store.state.roles)
    const id = computed(() => root.$route.params['id'])
    const accessRole = ref('')
    const revisionId = ref('')
    const revisions = ref([])
    const version = ref('Loading')
    const status = ref('Inactive')
    const activeRevision = ref('')
    const editingRevision = ref('')
    const revisionActive = ref(false)

    const name = ref('')
    const shortName = ref('')
    const department = ref('')
    const inList = ref(false)

    const editingName = ref(false)
    const nameEditor = ref(null)

    function startNameEdit () {
      editingName.value = true
      root.$nextTick(() => {
        nameEditor.value.focus()
      })
    }

    function checkUniqueName () {
      let query = { name: name.value }
      root.$feathers.service('forms/base').find({ query }).then(({ data }) => {
        let valid = true
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (JSON.stringify(data[i]._id) === id.value) continue
            else valid = false
          }
        }
        if (valid) {
          editingName.value = false
          root.$feathers.service('forms/base').patch(id.value, { name: name.value }).then(() => {
            root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Form name change successful' })
          })
        } else {
          alert('The form name that you entered is already in use by another form. Please enter a different name.')
          nameEditor.value.focus()
        }
      })
    }

    const selectedTab = computed({
      get: () => root.$store.state.forms.admin.activeTab,
      set: (val) => root.$store.commit('forms/setAdminTab', val)
    })
    const submissions = ref([])

    const showTitle = ref(true)
    const active = ref(false)
    const sectionType = ref('card')
    const sections = ref([{ title: 'Loading...', showTitle: true, inputs: [] }])
    const infoCard = ref(false)
    const infoCardText = ref('')
    const asteriskOnRequired = computed({
      get: () => root.$store.state.forms.formSettings.asteriskOnRequired,
      set: (asteriskOnRequired) => root.$store.commit('forms/setFormSettings', { id: id.value, asteriskOnRequired })
    })

    const sectionTypes = ref([
      { text: 'Card', value: 'card' },
      { text: 'Stepper (Horizontal)', value: 'stepper' },
      { text: 'Stepper (Vertical)', value: 'stepper-v' }
    ])
    const sectionTypeText = computed(() => {
      for (const { text, value } of sectionTypes.value) {
        if (value === sectionType.value) return text
      }
      return 'Card'
    })
    function addSection () {
      sections.value.push({
        title: 'New Section',
        showTitle: true,
        inputs: []
      })
    }
    function updateSection ({ sectionIndex, val }) {
      let obj = Object.assign({}, sections.value[sectionIndex])
      for (let l in val) obj[l] = val[l]
      sections.value.splice(sectionIndex, 1, obj)
      updateActivityTimer()
    }
    function removeSection (index) {
      sections.value.splice(index, 1)
    }

    async function newRevision () {
      // If there is a current revision loaded, copy it to create the new revision
      let revision = {}
      if (revisionId.value) {
        revision = await root.$feathers.service('forms/revision').get(revisionId.value)
        delete revision._id
        let { data } = await root.$feathers.service('forms/revision').find({ query: { form: id.value, $sort: { version: -1 }, $limit: 1 } })
        revision.version = data[0].version + 1
      } else {
        revision = {
          form: id.value,
          version: 1,
          active: false,
          sectionType: 'card',
          infoCard: false,
          sections: [
            {
              title: 'New Section',
              showTitle: true,
              inputs: []
            }
          ]
        }
      }
      root.$feathers.service('forms/revision').create(revision).then((data) => {
        revisions.value.push({ version: data.version, _id: data._id })
        editingRevision.value = data._id
        root.$feathers.service('forms/base').patch(id.value, { 'revisions.editing': data._id })
        setTimeout(() => { revisionId.value = data._id }, 250)
      })
    }

    onMounted(() => {
      if (id.value != null && id.value !== '') {
        root.$feathers.service('forms/base').get(id.value).catch((e) => {
          root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'You do not have access to that form.' })
          root.$router.replace('/forms/admin')
        }).then((data) => {
          name.value = data.name
          shortName.value = data.shortName
          department.value = data.department
          inList.value = data.inList || false
          status.value = data.status
          editingRevision.value = 'revisions' in data ? data.revisions.editing || null : null
          activeRevision.value = 'revisions' in data ? data.revisions.active || null : null
          if ('access' in data && 'users' in data.access) {
            data.access.users.forEach(({ pidm, role }) => {
              if (pidm === user.value.pidm) {
                if (role === 'Removed') {
                  root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'You do not have access to that form.' })
                  root.$router.replace('/forms/admin')
                  return
                }
                accessRole.value = role
              }
            })
            if (accessRole.value === '') {
              root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'You do not have access to that form.' })
              root.$router.replace('/forms/admin')
              return
            }
          }
          if ('settings' in data && Array.isArray(data.settings) && data.settings.length > 0) {
            const settings = { id: id.value, asteriskOnRequired: false }
            for (const { name, value } of data.settings) {
              if (name === 'asteriskOnRequired') asteriskOnRequired.value = value
              settings[name] = value
            }
            root.$store.commit('forms/setFormSettings', settings)
          } else {
            asteriskOnRequired.value = false
          }
          if (!('revisions' in data) || (data.revisions.editing == null && data.revisions.active == null)) {
            // We have no active or editing revision; create a new revision and set it as the editing revision
            newRevision()
          } else if (data.revisions.editing != null) {
            setTimeout(() => { revisionId.value = data.revisions.editing }, 250)
          } else {
            setTimeout(() => { revisionId.value = data.revisions.active }, 250)
          }
          loadRevisions()
        })
      } else {
        root.$router.replace('/forms/admin')
      }
    })

    function loadRevisions () {
      root.$feathers.service('forms/revision').find({ query: { form: id.value, $select: ['version', '_id'], $limit: 50 } }).then(({ data }) => {
        revisions.value = data
      })
    }

    function selectRevision (obj) {
      revisionId.value = obj.revisionId
      if (accessRole.value === 'Admin') selectedTab.value = 1
      else if (accessRole.value === 'Editor') selectedTab.value = 0
    }

    watch(revisionId, () => {
      if (revisionId.value !== '') {
        root.$feathers.service('forms/revision').get(revisionId.value).then((data) => {
          load(data)
        })
      }
    })

    function save () {
      const settings = []
      if (asteriskOnRequired.value) {
        settings.push({ name: 'asteriskOnRequired', value: asteriskOnRequired.value })
      }
      // Update the form base with the settings
      root.$feathers.service('forms/base').patch(id.value, { settings }).catch((err) => {
        alert('Error upating form settings: ' + err)
      })
      let revision = {
        active: active.value,
        showTitle: showTitle.value,
        sectionType: sectionType.value,
        sections: sections.value,
        infoCard: infoCard.value,
        infoCardText: infoCardText.value
      }
      return root.$feathers.service('forms/submission').find({ query: { 'form.base': id.value, 'form.revision': revisionId.value, $limit: 0 } }).then(({ total }) => {
        if (total === 0) {
          root.$feathers.service('forms/revision').patch(revisionId.value, revision).catch((err) => {
            alert(err)
          }).then(() => {
            root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Form revision was saved successfully' })
          })
        } else {
          // There has been at least one submission connected with this form revision; check to see if this is the latest form revision or if we should create a new revision
          root.$feathers.service('forms/revision').find({ query: { form: id.value, version: { $gt: version.value }, $sort: { version: -1 } } }).then(({ data, total }) => {
            if (data.length > 0 && data[0].submissions === 0) {
              // update this one then select it
              root.$feathers.service('forms/revision').patch(data[0]._id, revision).catch((err) => {
                alert(err)
              }).then((data) => {
                load(data)
                root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Form revision was saved successfully' })
              })
            } else {
              root.$feathers.service('forms/revision').get(revisionId.value).then((data) => {
                revision.confText = data.confText
                revision.submissions = 0
                revision.form = id.value
                revision.version = (data.length > 0 ? data[0].version : version.value) + 1
                root.$feathers.service('forms/revision').create(revision).then((data) => {
                  revisions.value.push({ version: data.version, _id: data._id })
                  load(data)
                  root.$feathers.service('forms/base').patch(id.value, { 'revisions.editing': data._id })
                  root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Form revision was saved successfully' })
                })
              })
            }
          })
        }
      })
    }

    function load (data) {
      revisionActive.value = data.active
      version.value = data.version
      showTitle.value = data.showTitle || true
      sectionType.value = data.sectionType
      sections.value = []
      for (let i = 0; i < data.sections.length; i++) {
        sections.value.push(data.sections[i])
      }
      infoCard.value = data.infoCard
      infoCardText.value = data.infoCardText
      revisionId.value = data._id
    }

    function makeRevisionActive () {
      save().then(() => {
        let obj = { 'revisions.active': revisionId.value }
        root.$feathers.service('forms/base').patch(id.value, obj).then(() => {
          root.$feathers.service('forms/revision').patch(revisionId.value, { active: true }).then(load)
          root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Form revision was made active' })
          load()
          updateActivityTimer()
        })
      })
    }

    const statusMenu = ref(false)
    function changeActiveStatus (newStatus) {
      if (accessRole.value !== 'Admin') return
      if (newStatus === 'Active') {
        // Make the current revision the active one
        active.value = true
        save().then(() => {
          let obj = { status: 'Active', 'revisions.active': revisionId.value }
          root.$feathers.service('forms/base').patch(id.value, obj).then(() => {
            root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Form was made active with the currently selected revision' })
            activeRevision.value = revisionId.value
            active.value = true
            status.value = 'Active'
            updateActivityTimer()
          })
        })
      } else {
        root.$feathers.service('forms/base').patch(id.value, { status: newStatus }).then((data) => {
          status.value = data.status
          root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Form was marked ' + newStatus + '.' })
        })
      }
    }

    const helpDialogActive = ref(false)

    function updateActivityTimer () {
      root.$store.commit('updateActivity')
    }
    const infoCardHelp = ref(false)
    const formSettingsDialog = ref(false)

    return {
      user,
      userRoles,
      id,
      accessRole,
      revisionId,
      revisions,
      version,
      status,
      activeRevision,
      editingRevision,
      revisionActive,
      name,
      shortName,
      department,
      inList,
      editingName,
      nameEditor,
      startNameEdit,
      checkUniqueName,
      selectedTab,
      submissions,
      showTitle,
      active,
      sectionType,
      sections,
      infoCard,
      infoCardText,
      asteriskOnRequired,
      sectionTypes,
      sectionTypeText,
      addSection,
      updateSection,
      removeSection,
      newRevision,
      helpDialogActive,
      loadRevisions,
      selectRevision,
      save,
      changeActiveStatus,
      makeRevisionActive,
      statusMenu,
      updateActivityTimer,
      infoCardHelp,
      formSettingsDialog
    }
  }
}
</script>
